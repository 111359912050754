import request from "./common/api_client";

function getDetails(params) {
    return request({
        url: '/users/profile',
        method: 'GET',
        params: params
    })
}

function getUserList(params) {
    return request({
        url: '/v1/admin/users',
        method: 'GET',
        params: params
    })
}

function createUser(formData) {
    return request({
        url: '/v1/admin/users',
        method: 'POST',
        data: formData
    })
}

function updateUser(formData) {
    return request({
        url: '/v1/admin/users',
        method: 'PUT',
        data: formData
    })
}
function deleteUser(id) {
    return request({
        url: `/users/profile/${id}`,
        method: 'DELETE',
    })
}

function resetPassword(formData) {
    return request({
        url: `v1/admin/users/trigger_credentials`,
        method: 'POST',
        data: formData
    })
}

function getTenants() {
    return request({
        url: '/v1/admin/tenants',
        method: 'GET',
        params: {
            page_size: 200
        }
    })
}

const UserClient = {
    getDetails, getUserList, createUser, updateUser, deleteUser, getTenants, resetPassword
}

export default UserClient;
