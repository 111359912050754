import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {ToastMessageContext} from "../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import UserClient from "../../lib/services/api/user";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialog-paper': {
            padding: '20px'
        }
    },
    title: {
        fontSize: '1.2em',
        fontWeight: 'bold'
    },
    content: {
        width: 400,
    }
}))

function ManageUser(props) {

    const classes = useStyles();
    const {handleClose, action, currentSelection} = props;
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [error, setError] = useState({});
    const message = useContext(ToastMessageContext);

    useEffect(() => {
        UserClient.getTenants().then((res) => {
            if (res?.results?.length > 0) {
                setTenantList(res?.results);
            }
        }).catch((e) => {
        })
    }, []);

   const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const tenant = formData.get('tenant');
    const name = formData.get('first_name');
    const email = formData.get('username');

    const errors = {};
    const capitalizeWordRegex = /^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!tenant) {
        errors.tenant = "Tenant must be selected.";
    }
    if (!name) {
        errors.name = "Name is required.";
    } else if (!capitalizeWordRegex.test(name)) {
        errors.name = "Name must contain only capitalized words (e.g., John Doe).";
    }
    if (!email) {
        errors.email = "Email address is required.";
    } else if (!emailRegex.test(email)) {
        errors.email = "Invalid email address.";
    }

    if (Object.keys(errors).length > 0) {
        setError(errors);
        setLoading(false);
        return;
    }

    setLoading(true);
    setError({});

    if (action === 'create') {
        UserClient.createUser(formData).then((res) => {
            if (res.success) {
                message.showSuccess("New User Added Successfully");
                const data = {
                    id: res?.data?.id,
                    name: formData.get('first_name'),
                    first_name: formData.get('first_name'),
                    username: formData.get('username'),
                };
                handleClose(data);
            } else {
                message.showError("User already exists.");
                handleClose();
            }
        }).catch((err) => {
            message.showError(err?.message.username || "Something went wrong.");
            handleClose();
        });
    } else if (action === 'update') {
        formData.append('id', currentSelection.id);
        UserClient.updateUser(formData, currentSelection.id).then((res) => {
            if (res.success) {
                message.showSuccess("User Details Updated Successfully");
                handleClose({ ...currentSelection, name: formData.get('first_name') });
            }
        }).catch((e) => {
            message.showError("Something went wrong.");
            handleClose();
        });
    }
};

    const handleDelete = () => {
        setLoading(true);
        UserClient.deleteUser(currentSelection.id).then((res) => {
            if (res.success) {
                message.showSuccess("User Removed Successfully");
                handleClose(currentSelection);
            } else {
                handleClose(currentSelection);
                message.showError("Something went wrong. Try again!");
            }
        }).catch((e) => {
            handleClose({id: Date.now()});
            message.showError("Something went wrong. Try again!");
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleReset = () => {
        setLoading(true);
        const requestData = {
            username: currentSelection.username,
        };
        UserClient.resetPassword(requestData).then((res) => {
            if (res.success) {
                message.showSuccess("Mail Sent Successfully");
                handleClose(currentSelection);
            } else {
                handleClose(currentSelection);
                message.showError("Something went wrong. Try again!");
            }
        }).catch((e) => {
            handleClose({id: Date.now()});
            message.showError("Something went wrong. Try again!");
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <Dialog className={classes.root} minWidth={"md"} open={true} onClose={() => null}
                aria-labelledby="form-dialog-title">
            {action !== 'reset' && action !== 'delete' && <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                    <Typography variant={'subtitle2'} component={'div'}
                                className={classes.title}> {action === 'create' ? "Add New" : "Update"} User</Typography></DialogTitle>
                <DialogContent className={classes.content}>
    <DialogContentText>
        {action === 'create'
            ? "Add new user to provide them access to use system to manage their batches. After successful creation user will get login credentials via email."
            : "Modify User details"}
    </DialogContentText>

    <TextField
        color="primary"
        label={"Tenant"}
        placeholder={"Select Tenant"}
        name={"tenant"}
        fullWidth
        select
        error={!!error.tenant}
        helperText={error.tenant}
        SelectProps={{
            MenuProps: {
                style: { maxHeight: 300 },
                anchorOrigin: { vertical: "bottom" },
            },
        }}
        variant="outlined"
    >
        {tenantList.map((t, i) => (
            <MenuItem key={t.id} value={t.id}>
                {t.name}
            </MenuItem>
        ))}
    </TextField>

    <TextField
        fullWidth
        variant={'outlined'}
        required
        name="first_name"
        label={"Name"}
        placeholder="Name"
        autoFocus
        defaultValue={currentSelection.name}
        error={!!error.name}
        onFocus={() => setError({})}
        helperText={error.name}
    />

    <TextField
        fullWidth
        variant={"outlined"}
        required
        name="username"
        label={"Email Address"}
        defaultValue={currentSelection.username}
        placeholder="Email address"
        error={!!error.email}
        inputProps={{ readOnly: action === 'update' }}
        onFocus={() => setError({})}
        helperText={error.email || (action === 'update' ? "*Email address cannot be changed" : null)}
    />
</DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading}
                            startIcon={loading && <CircularProgress size={15}/>} variant={'contained'} type={"submit"}
                            color="primary">
                        {action === 'create' ? 'Create' : 'Update'}
                    </Button>
                </DialogActions>
            </form>}
            {action === 'delete' && <div>
                <DialogTitle id="form-dialog-title">Remove User?</DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText>
                        Removing an user will remove all their details and associated batches they have processed in the
                        system
                    </DialogContentText>
                    <FormControlLabel
                        control={<Checkbox checked={confirm} onChange={(e) => setConfirm(e.target.checked)}
                                           name="agree"/>}
                        label={`Yes, I want to remove "${currentSelection.username}"`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading || !confirm}
                            startIcon={loading && <CircularProgress size={15}/>} variant={'contained'}
                            type={"submit"}
                            color="primary"
                            onClick={handleDelete}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </div>}
            {action === 'reset' && <div>
                <DialogTitle id="form-dialog-title">Password Reset?</DialogTitle>
                <DialogContent className={classes.content}>
                <DialogContentText>
                    Are you sure you want to reset the password for this user? A password reset email will be sent to their registered email address.
                </DialogContentText>
                <FormControlLabel
                    control={<Checkbox checked={confirm} onChange={(e) => setConfirm(e.target.checked)} name="confirm" />}
                    label={`Yes, send a password reset email to "${currentSelection.username}"`}
                />
            </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading || !confirm}
                            startIcon={loading && <CircularProgress size={15}/>} variant={'contained'}
                            type={"submit"}
                            color="primary"
                            onClick={handleReset}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </div>}
        </Dialog>
    );
}

export default ManageUser;
