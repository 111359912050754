import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ClockIcon from "@material-ui/icons/AccessAlarm";
import ProcessingIcon from "@material-ui/icons/Sync";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewIcon from "@material-ui/icons/Assessment";
import {Tooltip} from "@material-ui/core";

export const statusMap = {
    "TO_UPLOAD": {
        text: "Initiating batch",
        icon: <InfoIcon className={"warning"}/>,
        actionLabel: "Pending Upload",
        actionIcon: <CircularProgress size={14} color={"inherit"}/>,
    },
    "NOT_UPLOADED": {
        text: "Files not Uploaded",
        icon: <ErrorIcon className={"negative"}/>,
        actionLabel: "View Errors",
        actionIcon: <ErrorIcon className={"negative"}/>
    },
    "UPLOADED": {
        text: "Files Uploaded",
        icon: <InfoIcon className={"warning"}/>,
        actionLabel: "Queuing",
        actionIcon: <CircularProgress size={14} color={"inherit"}/>
    },
    "PROCESSING": {
        text: "Processing",
        note: "DISPLAY_STAT",
        actionLabel: "Processing...",
        actionIcon: <CircularProgress size={14} color={"inherit"}/>
    },
    "PROCESSED": {
        text: "Batch Processed",
        isComplete: true,
        icon: <CheckIcon className={"positive"}/>,
        actionLabel: "View Analysis",
        actionIcon: <ViewIcon/>
    },
    "PROCESSED_MANUALLY": {
        text: "Batch Processed",
        icon: <CheckIcon className={"positive"}/>,
        actionLabel: "View Analysis",
        actionIcon: <ViewIcon/>
    },
    "PROCESSING_MANUALLY": {
        text: "Awaiting Manual Output",
        icon: <ClockIcon className={"warning"}/>,
        actionLabel: "View Details",
        actionIcon: <ViewIcon/>
    },
    "FAILED": {
        text: "Action Required",
        icon: <ErrorIcon className={"negative"}/>,
        actionLabel: "View Errors",
        details: true,
        actionIcon: <InfoIcon color={"error"}/>
    },
    "DATA_ERROR": {
        text: "Action Required",
        icon: <WarningIcon className={"negative"}/>,
        actionLabel: "View Errors",
        showInfo: true,
        details: true,
        actionIcon: <InfoIcon color={"error"}/>
    },
    "PAGES_MISSING": {
        text: "Action Required",
        icon: <WarningIcon className={"negative"}/>,
        actionLabel: "View Errors",
        showInfo: true,
        details: true,
        actionIcon: <InfoIcon color={"error"}/>
    },
    "NEW_BANK": {
        text: "Bank to be configured",
        icon: <ClockIcon className={"negative"}/>,
        actionLabel: "View Errors",
        actionIcon: <InfoIcon/>
    },
    "PARTIALLY_PROCESSED": {
        text: "Proceed with warning",
        icon: <WarningIcon className={"warning"}/>,
        actionLabel: "View Details",
        details: true,
        actionIcon: <InfoIcon/>
    },
    "DELETED": {
        text: "Expired",
        icon: <ErrorIcon className={"negative"}/>,
        actionLabel: "Expired",
        details: false,
        actionIcon: <InfoIcon color={"error"}/>
    },
    "FAILED_WITH_RESULTS": {
        text: "Batch Failed",
        icon: <ErrorIcon className={"negative"}/>,
        actionLabel: "View Details",
        details: true,
        actionIcon: <InfoIcon color={"error"}/>
    },
    "LIMIT_EXCEEDED": {
        text: "Action Required",
        icon: <ErrorIcon className={"negative"}/>,
        actionLabel: "View Errors",
        details: true,
        actionIcon: <InfoIcon color={"error"}/>
    },
}

function BatchStatus(props) {

    const {data, user} = props;
    const currentStatus = statusMap[data?.status];
    if (currentStatus?.text) {
        if (currentStatus?.hasOwnProperty('note')) {
            const processingCount = +data?.file_count - (+data?.valid_files + +data?.invalid_files + +data?.new_bank_files);
            return (<>
                    {+processingCount > 0 &&
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}} gutterBottom>
                        <ProcessingIcon className={"warning"}/> &nbsp; {+processingCount} Processing
                    </Typography>}
                    {data?.valid_files > 0 &&
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}}
                                gutterBottom>
                        <CheckIcon className={"positive"}/>&nbsp; {`${data.valid_files}`} Processed</Typography>
                    }
                    {data?.invalid_files > 0 &&
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}}
                                gutterBottom>
                        <ErrorIcon className={"negative"}/>&nbsp; {`${data.invalid_files}`} Rejected</Typography>
                    }
                    {data?.new_bank_files > 0 &&
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}}
                                gutterBottom>
                        <InfoIcon className={"warning"}/>&nbsp; {`${data.new_bank_files}`} New Bank</Typography>
                    }
                </>
            )
        } else {
            return (
                <Tooltip placement={"top"} className={"clickable"} arrow
                         title={currentStatus?.showInfo ? data?.additional_info?.description : ""}>
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}} gutterBottom>
                        {currentStatus.icon} &nbsp; {user?.user_role === 'BASIC_USER' ? currentStatus.text?.replace("Action Required", "Batch Failed") : currentStatus.text}
                    </Typography>
                </Tooltip>
            )
        }
    }
}

export default BatchStatus;
