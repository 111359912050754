import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ClockIcon from "@material-ui/icons/AccessAlarm";
import ProcessingIcon from "@material-ui/icons/Sync";
import PageIcon from "@material-ui/icons/MenuBook";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

export const fileStatusMap = {
    "TO_UPLOAD": {
        text: "Pending Upload",
        icon: <InfoIcon className={"warning"}/>
    },
    "NOT_UPLOADED": {
        text: "Files not Uploaded",
        icon: <ErrorIcon className={"negative"}/>
    },
    "UPLOADED": {
        text: "File Uploaded",
        icon: <InfoIcon className={"warning"}/>
    },
    "PROCESSING": {
        text: "Processing",
        icon: <ProcessingIcon className={"warning"}/>
    },
    "PROCESSED": {
        text: "Processed",
        icon: <CheckIcon className={"positive"}/>
    },
    "PROCESSED_MANUALLY": {
        text: "Manual Output Ready",
        icon: <CheckIcon className={"positive"}/>
    },
    "PROCESSING_MANUALLY": {
        text: "Awaiting Manual Output",
        icon: <ClockIcon className={"warning"}/>
    },
    "FAILED": {
        text: "Rejected",
        note: "SHOW_INFO"
    },
    "UNSUPPORTED_LANGUAGE": {
        text: "Not in English",
        note: "SHOW_INFO"
    },
    "INVALID_FILE": {
        text: "Invalid File",
        note: "SHOW_INFO"
    },
    "NOT_PROCESSABLE": {
        text: "Unprocessable File",
        note: "SHOW_INFO"
    },
    "FILE_0_BYTES": {
        text: "Corrupted File",
        note: "SHOW_INFO"
    },
    "BAD_SCAN": {
        text: "Bad Scan",
        note: "SHOW_INFO"
    },
    "PAGES_MISSING": {
        text: "Missing Pages",
        note: "SHOW_INFO"
    },
    "MISSING_MONTH": {
        text: "Missing Month",
        note: "SHOW_INFO"
    },
    "NEW_BANK": {
        text: "Bank to be configured",
        icon: <ClockIcon className={"negative"}/>
    },
    "SPLIT_FILE": {
        text: "Combined Statement",
        icon: <PageIcon className={"warning"}/>
    },
    "FAILED_WITH_RESULTS": {
        text: "Rejected",
        note: "SHOW_INFO"
    },
    "LIMIT_EXCEEDED": {
        text: "Rejected",
        note: "SHOW_INFO"
    },
}

function FileStatus(props) {

    const {data} = props;

    const currentStatus = fileStatusMap[data?.status];

    if (currentStatus?.text) {
        if (currentStatus?.hasOwnProperty('note')) {
            return (
                <Tooltip
                    arrow
                    title={<div style={{padding: '10px'}}>
                        <Typography style={{color: "#fff"}} variant={"subtitle1"}>
                            {data?.internal_admin_comments?.length > 2 ? data?.internal_admin_comments : data?.additional_info?.description}
                        </Typography>
                    </div>}
                >
                    <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}}
                                className={"clickable"}
                                gutterBottom><ErrorIcon className={"negative"}/>&nbsp;{currentStatus.text}</Typography>
                </Tooltip>
            )
        } else {
            return (
                <Typography style={{fontSize: '0.8em', display: 'flex', alignItems: 'center'}} gutterBottom>
                    {currentStatus.icon}&nbsp;{currentStatus.text}
                </Typography>
            )
        }
    }
}

export default FileStatus;
