import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MaterialTable from "material-table";
import tableIcons, {tableOptions} from "../../components/universal/table_attributes";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from "@material-ui/core/Button";
import ManageUser from "./manage_user";
import UserClient from "../../lib/services/api/user";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '3%'
    },
    title: {
        fontSize: '2em',
        padding: '5px 0'
    }
}))

function UserList(props) {

    const classes = useStyles();
    const [action, setAction] = useState();
    const [data, setData] = useState([]);
    const [tenantList, setTenantList] = useState([]);
    const [tenant, setTenant] = useState();
    const [currentSelection, setCurrentSelection] = useState({});

    useEffect(() => {
        UserClient.getTenants().then((res) => {
            if (res?.results?.length > 0) {
                setTenantList(res?.results);
            }
        }).catch((e) => {
        })
    }, [])

    useEffect(() => {
        const params = {
            'tenant_id': tenant
        }
        UserClient.getUserList(params).then((res) => {
            if (res.success) {
                setData(res.data?.user);
            }
        }).catch((e) => {
        });
    }, [tenant])


    const MITableOptions = {
        ...tableOptions,
        showTitle: false,
        paging: true,
        search: true,
        toolbar: false,
        selectableRowsOnClick: true,
        pageSize: 5
    }

    const columns = [
        {
            title: 'Name',
            field: 'first_name',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                padding: '15px'
            }
        },
        {
            title: 'Email',
            field: 'username',
            headerStyle: {
                textAlign: 'left'
            },
        },
        {
            title: 'Batches',
            field: 'batch_count',
            width: '75px',
            headerStyle: {
                textAlign: 'center',
                paddingLeft: '50px'
            },
            cellStyle: {
                textAlign: 'center'
            }
        }
    ];

    const handleClose = (newData) => {
        if (newData && newData.id) {
            if (action === "create") {
                setData([newData].concat(data));
            } else if (action === 'update') {
                const newUpdatedData = data.map(d => {
                    if (d.id === newData.id) {
                        return newData;
                    }
                    return d;
                })
                setData(newUpdatedData);
            } else if (action === 'delete') {
                const newUpdatedData = data.filter(d => {
                    return d.id !== newData.id;
                });
                setData(newUpdatedData);
            }
        }
        setCurrentSelection({});
        setAction(null);
    }

    return (
        <div className={classes.root}>
            <div className={"corner-aligned"}>
                <Typography className={classes.title} variant={'subtitle2'} gutterBottom>
                    Users
                </Typography>
                <Button color={"primary"} size={"small"} variant={"contained"} startIcon={<AddIcon/>}
                        onClick={() => setAction('create')}>
                    Add New User
                </Button>
            </div>
            <div>
                <TextField
                    color="primary"
                    margin="dense"
                    label={"Tenant Filter"}
                    placeholder={"All Tenants"}
                    style={{width: '300px'}}
                    select
                    SelectProps={{
                        MenuProps: {
                            style: {
                                maxHeight: 450,
                            },
                            anchorOrigin: {
                                vertical: "bottom"
                            }
                        },
                    }}
                    variant="outlined"
                    value={tenant}
                    onChange={(e) => {
                        setTenant(e.target.value);
                    }}
                >
                    {tenantList.map((t, i) => (
                        <MenuItem key={t.id} value={t.id}>
                            {t.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <MaterialTable
                style={{boxShadow: 'unset'}}
                columns={columns}
                data={data || []}
                options={MITableOptions}
                icons={tableIcons}
                onRowClick={(event, rowData) => {
                    event.stopPropagation();
                    props.history.push(`/user/${rowData.id}/batches`);
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No Users found, Add new user to view details',
                    }
                }}
                actions={[
                    {
                        icon: () => <DeleteIcon size={"small"} htmlColor={"#00000080"}/>,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                            event.stopPropagation();
                            setCurrentSelection(rowData);
                            setAction('delete');
                        }
                    },
                    {
                        icon: () => <VpnKeyIcon size={"small"} htmlColor={"#00000080"}/>,
                        tooltip: 'Reset',
                        onClick: (event, rowData) => {
                            event.stopPropagation();
                            setCurrentSelection(rowData);
                            setAction('reset');
                        }
                    },
                ]}
            />
            {action && <ManageUser action={action} handleClose={handleClose} currentSelection={currentSelection}/>}
        </div>
    );
}

export default UserList;
